export function getCookie(name: string) {
  return document.cookie.split(';').some((c) => {
    return c.trim().startsWith(name + '=');
  });
}

export function deleteCookie(name: string, path: string, domain: string) {
  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
}

export function eraseCookie(name: string) {
  document.cookie = name + '=; Max-Age=-99999999;';
}

export function getProperty<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

export function isPlural(count: number): boolean {
  return count !== 1;
}
